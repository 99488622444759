import { useState, useEffect } from 'react'
import './style.css'
import { Autoplay } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';
import CandlesPic from '../../assets/29.png'
import axios from 'axios';
import { URL } from '../../Settings';

function parseXML(data) {
    let arr = [];
    const items = data.rss.channel.item;
    for (const item of items) {
        if (item.title && item.title != '')
            arr.push({
                title: item.title,
                img: item.description.split('<br/>')[0],
                author: item.Author + ' (מעריב)',
            });
    }
    return arr;
}

export default function News({ settings }) {

    const [showShabbat, setShowShabbat] = useState(true)
    const [Items, setItems] = useState(null)
    const [rss, setRss] = useState([])

    function GetShabbat() {
        axios.get(`${URL.rss}/shabbat`, { headers: { 'Content-Type': 'application/json', 'Cache-Control': 'no-cache', 'withCredentials': 'true', 'Access-Control-Allow-Origin': '*' } }).then(res => {
            if (res.status == 200)
                setItems({
                    parashat: res.data.find(item => item.item1 == "parashat")?.item2,
                    candles: res.data.find(item => item.item1 == "candles")?.item2,//.slice(12),
                    havdalah: res.data.find(item => item.item1 == "havdalah")?.item2,//.slice(17)
                })
        }).catch((err) => console.log(err))
    }
    function GetRss() {
        axios.get(`${URL.rss}/maariv`, { headers: { 'Content-Type': 'application/json', 'Cache-Control': 'no-cache', 'withCredentials': 'true', 'Access-Control-Allow-Origin': '*' } }).then(res => {
            if (res.status == 200)
                setRss(parseXML(res.data))
        }).catch((err) => console.log(err))
    }
    useEffect(() => {
        shabbat();
        GetRss();
        setInterval(() => {
            shabbat();
        }, 360 * 60 * 1000);
        setInterval(() => {
            GetRss();
        }, 20 * 60 * 1000)
    }, [])

    function shabbat() {
        var today = new Date();
        var todayNum = today.getDay();
        const show = (todayNum == 4 || todayNum == 5 || todayNum == 6);
        if (show) {
            GetShabbat();
        }
        setShowShabbat(show);
    }
    return (
        <div className="left-sidebar-wrapper">
            {settings?.marketRssLeft &&
                <div className="card market">
                    {/* <app-market-rss></app-market-rss> */}
                </div>
            }
            <div style={{ marginBottom: showShabbat && '8px' }} className={`bulletin ${!settings?.marketRssLeft ?
                (!(settings?.shabbatLeft && showShabbat) ? 'news-block_MarketRss-hidden_CardBoxWithHeading-hidden' : 'news-block_MarketRss-hidden') :
                (!(settings?.shabbatLeft && showShabbat) ? 'news-block_CardBoxWithHeading-hidden' : '')}`}>
                <Swiper
                    slidesPerView={'auto'}
                    // slidesPerGroup={1}
                    spaceBetween={10}
                    direction={'vertical'}
                    //loop={rss.length > 10}
                    modules={[Autoplay]}
                    autoplay={{
                        delay: 2500,
                        disableOnInteraction: false,
                    }}
                >
                    {rss.map((item, i) => (
                        <SwiperSlide className='swiper-slides' key={i}>
                            <div className="sidebar-news-block">
                                <div className="pic-area" dangerouslySetInnerHTML={{ __html: item.img }}>
                                </div>
                                <div className="text-area">
                                    <span className="headline" style={{ fontSize: settings.newsLeftMainFontSize }}>{item.title}</span>
                                    <br />
                                    <span className="subtitle" style={{ fontSize: settings.newsLeftSecondFontSize }}>{item.author}</span>
                                </div>
                            </div>
                        </SwiperSlide >
                    ))
                    }
                </Swiper >
            </div >
            {settings?.shabbatLeft && showShabbat &&
                <div className="card card-3 g-0" >
                    <div className="card-3-area">
                        <div className="card-heading" style={{ fontSize: settings.shabbatLeftMainFontSize }}>
                            <h3>שבת <b>{Items?.parashat}</b></h3>
                        </div>
                        <div className="card-content row g-0">
                            <div className="card-left-side">
                                <ul className='shabbat-ul'>
                                    <li className='shabbat-li' style={{ fontSize: settings.shabbatLeftSecondFontSize }}>
                                        <span className="text">כניסת שבת</span>
                                        <span className="time"><b>{Items?.candles}</b></span>
                                    </li>
                                    <li className='shabbat-li' style={{ fontSize: settings.shabbatLeftSecondFontSize }}>
                                        <span className="text">יציאת שבת</span>
                                        <span className="time"><b>{Items?.havdalah}</b></span>

                                    </li>
                                </ul>
                            </div>
                            <div className="card-right-side">
                                <img className='shabbat-img' src={CandlesPic} alt="Candles" />
                            </div>
                        </div>
                    </div >

                </div >
            }
        </div >
    )
}


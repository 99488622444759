
import './style.css'
import Header from '../Header'
import Time from '../Time'
import SidebarImage from '../SidebarImage'
import NoticeBoard from '../NoticeBoard'
import HeaderMessages from '../HeaderMessages'
import MediaSlider from '../MediaSlider'
import Headlines from '../Headlines'
import News from '../News'
import Audio from '../Audio'
import { useState } from 'react'


export default function Layout({ settings, data, messages, building, media }) {
    const [hideSidebarImage, setHideSidebarImage] = useState(true)
    return (
        <>
            <div className="row g-0 header-container-wrapper">
                <div className={`g-0 ${settings?.headerUpClass}`}>
                    <Header settings={settings} data={data} building={building} />
                </div>
                <div className={settings?.dateUpClass}>
                    <Time settings={settings} rssItems={null} />
                </div>
            </div>
            <div className="row g-0 body-container-wrapper">
                {settings?.sidebarRight &&
                    <div className={`custom-gap move-up ${settings?.sidebarRightClass}`}>
                        {media.filter(x => x.Title == '2').length > 0 && settings?.imageExpandedRight &&
                            <div className="right-sidebar-horz-slider"
                                style={{ maxHeight: `${settings?.imageExpandedRightHeight}px`, flex: `0 0 ${settings?.imageExpandedRightHeight}px` }}>
                                <SidebarImage media={media.filter(x => x.Title == '2')} />
                            </div>
                        }
                        {settings?.messageMainRight &&
                            <div className={`right-sidebar-chat-box ${(hideSidebarImage || (!settings?.imageExpandedRight)) && 'sidebar-image-hidden'}`}
                                style={{
                                    maxHeight: `${((!hideSidebarImage) && settings?.imageExpandedRight) ? `calc(100% - ${settings.imageExpandedRightHeight}px)` : '100%'}`,
                                    flex: `${(!hideSidebarImage) && settings?.imageExpandedRight ? `0 0 calc(100% - ${settings?.imageExpandedRightHeight}px)` : '100%'}`
                                }}>
                                <NoticeBoard msgs={messages.filter((x) => x.Title == '1')} settings={settings} />
                            </div >
                        }
                    </div>
                }
                {settings?.sidebarMiddle &&
                    <div className={`custom-gap ${settings.sidebarMiddleClass}`}>
                        {settings.messageExpanded &&
                            <div className="msg-section-move-up" style={{ width: settings.messageExpandedWidth || '70%' }}>
                                <HeaderMessages settings={settings} msgs={messages.filter((x) => x.Title == '2')} />
                            </div >
                        }
                        <div className='center-media-container'><MediaSlider type={1} media={media.filter(x => x.Title == '1')} /></div>
                    </div >
                }
                {settings?.sidebarMiddle2 &&
                    <div className={`custom-gap ${settings.sidebarMiddle2Class}`}>
                        {/* <MediaSlider type={2} media={media.filter(x => x.Title == '2')} /> */}
                    </div>
                }
                {settings?.sidebarLeft &&
                    <div className={`custom-gap ${settings.sidebarLeftClass}`}>
                        <News settings={settings} />
                    </div>
                }
            </div >
            {settings?.rssBottom &&
                <div className="row  g-0 footer-container-wrapper">
                    <div className="col-12">
                        <Headlines />
                    </div>
                </div>
            }
            <div style={{ visibility: 'hidden' }}><Audio settings={settings} media={media.filter(x => x.Title == '3')} /></div>
        </>
    )
}
import { useEffect, useState } from 'react';
import './App.css';
import Layout from './Components/Layout';
import axios from 'axios';
import moment from 'moment';
import Settings from './Settings';
import { URL } from './Settings';
import { useParams } from 'react-router-dom';

let countDown = null;
let floorId = 0;
let lastUpdateTime = ""
function App() {
  const { floorNumber, buildingId } = useParams();
  const [settings, setSettings] = useState(Settings);
  const [data, setData] = useState(null);
  const [building, setBuilding] = useState(null);
  const [messages, setMessages] = useState([]);
  const [media, setMedia] = useState([]);


  useEffect(() => {
    // if (updateTime != "") {

    // }

  }, [])

  useEffect(() => {
    Get();
  }, [])

  function Get() {
    if ((floorNumber || floorNumber == 0) && buildingId && buildingId != 0) {
      GetMessages();
      GetData();
      GetBuilding();
      GetImages();
    } else {
      window.open(`https://files.nuvola.co.il/NotWorking?floorid=${floorNumber}`, "_self")
    }
  }

  function GetMessages() {
    axios.get(`${URL.api}/GetMessageActive?buildingId=${buildingId}`).then(res => {
      if (res.status == 200) {
        const now = Date.now();
        setMessages([...res.data.filter((x) => moment().isAfter(x.DateStart) && moment().isBefore(x.DateEnd))])
      }
    }).catch((err) => console.log(err))
  }
  function GetData() {
    axios.get(`${URL.api}/getFloorByFloorNumber?floorNumber=${floorNumber}&buildingId=${buildingId}`).then(res => {
      if (res.status == 200 && res.data) {
        setData(res.data);
        let s = { ...settings }
        if (res.data.TypeScreen.SettingsScreen)
          s = { ...s, ...JSON.parse(res.data.TypeScreen.SettingsScreen) };
        if (res.data.SettingsScreen)
          s = { ...s, ...JSON.parse(res.data.SettingsScreen) };
        floorId = res.data.Id;
        lastUpdateTime = res.data.LastUpdateTime;
        setSettings(s);

        if (countDown)
          clearTimeout(countDown);
        countDown = setTimeout(() => {
          GetUpdateTime();
        }, res.data.EveryMillisecondGoToServer);

      }
    }).catch((err) => console.log(err))
  }
  function GetBuilding() {
    axios.get(`${URL.api}/GetBuilding?id=${buildingId}`).then(res => {
      if (res.status == 200)
        setBuilding(res.data);
    }).catch((err) => console.log(err))
  }
  function GetImages() {
    axios.get(`${URL.api}/GetImagePresent?buildingId=${buildingId}`).then(res => {
      if (res.status == 200)
        setMedia(res.data);
    }).catch((err) => console.log(err))
  }
  function GetUpdateTime() {
    axios.get(`${URL.management}/GetLastUpdateTimeByFloor?floorId=${floorId}`).then(res => {
      if (res.status == 200 && res.data)
        if (lastUpdateTime != res.data) {
          Get();
        }
    }).catch((err) => console.log(err))
  }
  if ((floorNumber || floorNumber == 0) && buildingId && buildingId != 0) {
    return <Layout settings={settings} data={data} messages={messages} building={building} media={media} />

  }
  return (
    <></>
  );
}

export default App;

import { useMemo } from 'react';
import './style.css'
import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay } from 'swiper/modules';
import { useParams } from 'react-router-dom';
// import $ from 'jquery';


const $ = window.$;
const url = "https://api.view.nuvola.co.il/"
export default function MediaSlider({ type, media }) {

    const mute = useMemo(() => type == 3, [])
    const { buildingId} = useParams();

    function postMessageToPlayer(player, command) {
        if (player == null || command == null) return;
        player.contentWindow.postMessage(JSON.stringify(command), "*");
    }
    function Play(currentId) {
        // console.log("play", currentId)
        const currentEle = document.getElementById(currentId);
        if (currentEle) {
            if (currentEle.nodeName === "VIDEO") {
                currentEle.play();
            } else if (currentEle.nodeName === "IFRAME") {
                postMessageToPlayer(currentEle, {
                    "event": "command",
                    "func": "mute"
                });
                postMessageToPlayer(currentEle, {
                    "event": "command",
                    "func": "playVideo"
                });
                if (mute) {
                    postMessageToPlayer(currentEle, {
                        "event": "command",
                        "func": "unMute"
                    });
                }
            }
        }


    }

    function resizePlayer(ratio) {

        var win = document.getElementById(`swiper-${type}`);
        if (!win)
            return 0;
        var width = win.clientWidth,
            playerWidth,
            height = win.clientHeight,
            playerHeight,
            ratio = ratio || 16 / 9;
        var iframs = win.getElementsByTagName("iframe");


        if (width / ratio < height) {
            playerWidth = Math.ceil(height * ratio);
            return playerWidth;
        } else {
            return '100%'
            // playerHeight = Math.ceil(width / ratio);
            // current.width = width;
            // current.style.left = 0;
            // current.style.top = (height - playerHeight) / 2;
            // current.width(width).height(playerHeight).css({
            //     left: 0,
            //     top: (height - playerHeight) / 2
            // });
        }

    }
    function Stop(previousId) {
        // console.log("stop", previousId)

        const previousEle = document.getElementById(previousId);
        if (previousEle) {
            if (previousEle.nodeName === "VIDEO") {
                previousEle.pause();
                previousEle.currentTime = 0;
            } else if (previousEle.nodeName == "IFRAME") {
                postMessageToPlayer(previousEle, {
                    "event": "command",
                    "func": "stopVideo"
                });
            }
        }
    }
    return (
        <Swiper
            slidesPerView={1}
            slidesPerGroup={1}
            direction={'horizontal'}
            className={`media-swiper-${type}`}
            modules={[Autoplay]}
            spaceBetween={1500}
            autoplay={{
                delay: 2500,
                disableOnInteraction: false,
            }}
            id={`swiper-${type}`}
            onSlideChange={(s) => {
                Play(`media-${type}-${s.activeIndex}`);
                if (s.activeIndex != s.previousIndex) {
                    Stop(`media-${type}-${s.previousIndex}`);
                }
            }}
        >
            {media.map((element, i) => {
                const id = `media-${type}-${i}`
                element.title = element.Title || null;
                element.name = element.Name || null;
                element.href = element.Value || null;
                element.from_date = element.DateStart || null;
                element.to_date = element.DateEnd || null;
                element.duration = element.Duration || "00:00:10";
                element.format = element.Type == 1 ? "image" : (element.Type == 4 ? "video" : "youtube");
                element.direction = false;

                if (element.format == "youtube") {
                    var href = element.href.split('&');
                    element.href = href[0];
                }

                const durArr = element.duration.split(':');
                var durInSecs = (Number(durArr[0]) * 60 * 60 + Number(durArr[1]) * 60 + Number(durArr[2]));
                var durInMilliSecs = durInSecs * 1000;

                var content = <iframe style={{ minWidth: resizePlayer(16 / 9) }} id={id} height="100%" className='iframe_youtube embed-player slide-media' src={`${element.href}?enablejsapi=1&controls=0&fs=0&iv_load_policy=3&rel=0&showinfo=0&loop=1&start=1`} allow="autoplay" ></iframe>

                if (element.format == "image") {
                    content = <img id={id} width={type === 1 && "100%"} height={type !== 1 ? "100%" : ''} data-duration={durInMilliSecs} style={{ objectFit: "cover" }} src={`${url}Resources/element_image/${buildingId}/${element.href}`} />
                }
                else if (element.format == "video") {
                    content = <video autoPlay={i === 0} id={id} preload="auto" data-duration={durInMilliSecs} height="100%" style={{ width: 'auto', objectFit: "cover" }} muted loop><source src={element.href} type="video/mp4" /></video>
                }
                if (i == 0 && element.format == "youtube") {
                    window.setTimeout(function () {
                        Play(`media-${type}-${i}`)
                    }, 2000);
                }
                return (
                    <SwiperSlide data-swiper-autoplay={durInMilliSecs} className='media-slides' key={i}>
                        {/* {({ isActive }) => {
                            return content
                        }} */}
                        {content}
                    </SwiperSlide>
                )
            })}
        </Swiper>
    )

}



const d = [
    {
        "Id": 17373,
        "Title": "1",
        "Name": "לשמור על הניקיון",
        "Value": "https://f005.backblazeb2.com/file/nuvola-view-uploads/1685292873.mp4",
        "DateStart": "2023-05-13T15:55:00",
        "DateEnd": "2023-06-30T23:00:00",
        "BuildingId": 1244,
        "Building": null,
        "IsActive": true,
        "Deleted": false,
        "Duration": "00:00:12",
        "Type": 4,
        "IsManager": false
    },
    // {
    //     "Id": 24298,
    //     "Title": "1",
    //     "Name": "נובולה - חג שבועות",
    //     "Value": "https://f005.backblazeb2.com/file/nuvola-view-uploads/1685292351.mp4",
    //     "DateStart": "2023-05-22T06:00:00",
    //     "DateEnd": "2023-05-27T23:00:00",
    //     "BuildingId": 1244,
    //     "Building": null,
    //     "IsActive": true,
    //     "Deleted": false,
    //     "Duration": "00:00:33",
    //     "Type": 4,
    //     "IsManager": false
    // },
    // {
    //   "Id": 24569,
    //   "Title": "1",
    //   "Name": "הכירו את WiWi אישורי הגעה בוואטסאפ",
    //   "Value": "https://filesamples.com/samples/video/mp4/sample_640x360.mp4",
    //   "DateStart": "2023-05-25T06:00:00",
    //   "DateEnd": "2023-06-01T23:00:00",
    //   "BuildingId": 1244,
    //   "Building": null,
    //   "IsActive": true,
    //   "Deleted": false,
    //   "Duration": "00:00:14",
    //   "Type": 4,
    //   "IsManager": false
    // },
    // {
    //   "Id": 24569,
    //   "Title": "1",
    //   "Name": "הכירו את WiWi אישורי הגעה בוואטסאפ",
    //   "Value": "https://download.samplelib.com/mp4/sample-10s.mp4",
    //   "DateStart": "2023-05-25T06:00:00",
    //   "DateEnd": "2023-06-01T23:00:00",
    //   "BuildingId": 1244,
    //   "Building": null,
    //   "IsActive": true,
    //   "Deleted": false,
    //   "Duration": "00:00:10",
    //   "Type": 4,
    //   "IsManager": false
    // },
    {
        "Id": 24569,
        "Title": "1",
        "Name": "הכירו את WiWi אישורי הגעה בוואטסאפ",
        "Value": "https://www.youtube.com/embed/moYlvCpH-i4",
        "DateStart": "2023-05-25T06:00:00",
        "DateEnd": "2023-06-01T23:00:00",
        "BuildingId": 1244,
        "Building": null,
        "IsActive": true,
        "Deleted": false,
        "Duration": "00:00:34",
        "Type": 2,
        "IsManager": false
    },
    // {
    //     "Id": 24570,
    //     "Title": "3",
    //     "Name": "Amy Winehouse - Back To Black",
    //     "Value": "https://www.youtube.com/embed/TJAfLE39ZZ8",
    //     "DateStart": "2023-05-25T06:00:00",
    //     "DateEnd": "2023-06-01T23:00:00",
    //     "BuildingId": 1244,
    //     "Building": null,
    //     "IsActive": true,
    //     "Deleted": false,
    //     "Duration": "00:04:01",
    //     "Type": 3,
    //     "IsManager": false
    // }
]
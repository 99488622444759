import { useState, useEffect } from 'react';
import './style.css'
import axios from 'axios';
import { URL } from '../../Settings';

function parseXML(data) {
    const tempData = data.IsraelCitiesWeatherForecastEvening || data.IsraelCitiesWeatherForecastMorning;
    const location = tempData?.Location.find(item => item.LocationMetaData.LocationId == 402)
    const tempToday = location.LocationData.TimeUnitData[0];

    if (!tempToday.Element[0]) {
        const a = tempToday.Element;
        tempToday.Element = [];
        tempToday.Element.push(a)
    }
    const tempObj = {
        minTemp: tempToday?.Element?.find(item => item.ElementName == 'Minimum temperature')?.ElementValue || tempToday.Element.ElementValue || null,//temp?.Element[3]?.ElementValue || null,
        maxTemp: tempToday?.Element?.find(item => item.ElementName == 'Maximum temperature')?.ElementValue || null//temp?.Element[1]?.ElementValue|| null
    }
    return tempObj;
}


export default function Time({ settings }) {
    const { date, time } = useDate();
    const [items, setItems] = useState(null);
    useEffect(() => {
        GetItems();
        setInterval(() => {
            GetItems();
        }, 360 * 60 * 1000)
    }, [])
    function GetItems() {
        axios.get(`${URL.rss}/ims`, { headers: { 'Content-Type': 'application/json', 'Cache-Control': 'no-cache', 'withCredentials': 'true', 'Access-Control-Allow-Origin': '*' } }).then(res => {
            if (res.status == 200)
                setItems(parseXML(res.data));
        }).catch(error => console.log(error))
    }
    return (
        <div className="time-and-weather">
            <div className="time" style={{ textAlign: 'center', fontSize: settings?.dateTimeFontSize }}>
                {time}
            </div>
            <div className="date" style={{ textAlign: 'center', fontSize: settings?.dateDateFontSize }}>
                {date}
            </div >
            <div className="weather">
                <div className="weather-temp d-flex" style={{ fontSize: settings?.dateWeatherFontSize }}>
                    {items?.maxTemp && <div>{items?.maxTemp}<sup>°</sup> - </div>}
                    {items?.minTemp && <div>{items?.minTemp}<sup>°</sup></div>}
                </div >
                <div className="text" style={{ fontSize: settings?.dateWeatherFontSize }} >
                    המעלות בחוץ:
                </div >
            </div >
        </div>
    )
}

const useDate = () => {
    const locale = 'he';
    const [today, setDate] = useState(new Date()); // Save the current date to be able to trigger an update

    useEffect(() => {
        const timer = setInterval(() => { // Creates an interval which will update the current data every minute
            // This will trigger a rerender every component that uses the useDate hook.
            setDate(new Date());
        }, 60 * 1000);
        return () => {
            clearInterval(timer); // Return a funtion to clear the timer so that it will stop being called on unmount
        }
    }, []);

    const day = today.toLocaleDateString(locale, { weekday: 'long' });
    const date = `${day}, ${today.getDate()} ${today.toLocaleDateString(locale, { month: 'long' })}\n\n`;

    const hour = today.getHours();
    const wish = `Good ${(hour < 12 && 'Morning') || (hour < 17 && 'Afternoon') || 'Evening'}, `;

    const time = today.toLocaleTimeString(locale, { hour: 'numeric', hour12: false, minute: 'numeric' });

    return {
        date,
        time,
        wish,
    };
};
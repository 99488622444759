
import { useEffect, useState } from "react"
import { Howl } from 'howler';
import { URL } from "../../Settings";
import { useParams } from "react-router-dom";

//const media = ['/sample-6s.mp3', '/file_example_MP3_700KB.mp3']
// const media = ['/sample-6s.mp3']
//let player;
export default function Audio({ media, settings }) {

    const [index, setIndex] = useState(null)
    const [player, setPlayer] = useState(null)
    const [mute, setMute] = useState(true);
    const { buildingId } = useParams();
    useEffect(() => {
        if (media.length > 0 && mute === false) {
            if (index == null) {
                setIndex(0)
            }
        } else if (player != null || index != 0) {
            setPlayer(null);
            setIndex(null);
        }
    }, [media, mute])

    useEffect(() => {
        setMute(MuteNow());
        setInterval(() => {
            setMute(MuteNow());
        }, 15 * 60 * 1000)

    }, [])

    function MuteNow() {
        const isMute = (shabbatNow() && settings?.muteShabbat) || (nightNow() && settings?.muteNight) || settings?.mute;
        console.log(`Background music mute? ${isMute}`)
        return isMute;
    }
    function shabbatNow() {
        var today = new Date();
        var hour = today.getHours();
        var todayNum = today.getDay();
        var nowShabbat = (todayNum == 5 && hour >= settings?.muteShabbatFrom) || (todayNum == 6 && hour < settings.muteShabbatTo)
        return nowShabbat;
    }

    function nightNow() {
        const today = new Date();
        var hour = today.getHours();
        var nowNight = (hour >= settings?.muteNightFrom || hour < settings.muteNightTo)
        return nowNight;
    }

    function PlayNext() {
        if (mute) {
            setIndex(null);
            setPlayer(null);
            return;
        }
        else if (media.length > 1) {
            let i = index + 1;
            if ((media.length - 1) === index) {
                i = 0;
            }
            console.log(`Now playing ${URL.media}/${buildingId}/${media[index].Value.replace("https://www.youtube.com/embed/", "")}.mp3`);
            setIndex(i)
        }
    }
    useEffect(() => {
        if (media.length > 0 && index != null && mute == false) {
            const playSrc = `${URL.media}/${buildingId}/${media[index].Value.replace("https://www.youtube.com/embed/", "")}.mp3`;
            setPlayer(new Howl({
                src: [playSrc],
                autoplay: true,
                loop: media.length == 1,
                onloaderror: function () {
                    console.log(`Error Loading background music ${playSrc}`);
                    PlayNext(this);
                },
                onend: function () {
                    console.log(`Background Music Ended ${playSrc}`);
                    if (MuteNow())
                        this.stop();
                    PlayNext(this);
                },
            }));
        }

    }, [index])
    return <></>
}
import './style.css'
import Logo from '../../assets/nuvola_logo.gif'
export default function Header({ settings, data, building }) {

    return (
        <div className="header-box">
            <div className="bg-with-text" >
                <h2 className='h2' style={{ fontSize: data?.TextSizeFloorNameInFloor }}>{data?.FloorNameToFloor}<img style={{ width: `${settings?.headerImageLogoWidth}%`, marginLeft: '10px', marginRight: '10px' }} src={building?.LogoBuilding} /></h2>
            </div >
            <div className="cm-logo">
                <img className='img' src={Logo} />
            </div>
        </div >

    )
}
import './style.css'
import MicPicture from '../../assets/Mic.PNG'
import { useMemo } from 'react'
import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay } from 'swiper/modules';
import Loader from '../Loader';
import moment from 'moment';


export default function NoticeBoard({ settings, msgs }) {
    const show = useMemo(() => true, []);
    // const slickOrNot = useMemo(() => true, []);
    return (
        <div className="custom-notice-board">
            <div className="card">
                <div className="card-heading">
                    <img className='img' src={MicPicture} alt="mic" />
                    <h2 className='h2' style={{ fontSize: '30px' }}>הודעות לדיירים</h2>
                </div>
                <div className="card-content">
                    {msgs.length == 0 && <Loader />}
                    {msgs.length > 0 && show &&
                        <div className="marquee-verticle" >
                            <div className="track">
                                <div className="messages-list">
                                    <Swiper
                                        slidesPerView={'auto'}
                                        //slidesPerGroupAuto
                                        slidesPerGroup={1}
                                        spaceBetween={30}
                                        direction={'vertical'}
                                        loop={msgs.length > 10}
                                        modules={[Autoplay]}
                                        autoplay={{
                                            delay: 2500,
                                            disableOnInteraction: false,
                                        }}
                                        className="swiper-noticeBoard"
                                    >
                                        {msgs.map((msg, i) => (
                                            <SwiperSlide className='swiper-slides' key={i}>
                                                <div className="li  mt-2 mb-1">
                                                    {msg && <span className="name" style={{ fontSize: settings?.messageMainRightFromFontSize }}>{msg.MessageSendBy}</span>}
                                                    <span className="message">
                                                        {msg && <div dangerouslySetInnerHTML={{ __html: msg.Text }} style={{ fontSize: settings?.messageMainRightFontSize }}></div>}
                                                        {msg && <div className="date" style={{ fontSize: settings?.messageMainRightDateFontSize }}>{moment(msg.DateStart).format("DD/MM/YYYY")}</div>}

                                                    </span >
                                                </div >
                                            </SwiperSlide>
                                        ))}
                                    </Swiper>
                                </div >
                            </div >
                        </div>
                    }

                    {/* {msgs.length > 0 && show && slickOrNot &&
                        <div className="box-container floors-list" fxLayout="column" >
                            <div className="horizontal-container" fxLayout="row" fxLayout-lt-sm="column" fxLayoutAlign="stretch" fxFlex="auto">
                                <div className="vertical-container" fxLayout="column" fxLayoutAlign="stretch" fxFlex="50%">
                                    <div className="content-container" fxLayout="column" fxLayoutAlign="stretch" fxFlex="auto" style={{ height: '967px' }}>
                                        <div className="swiper-container">
                                            <div className="swiper-wrapper">
                                                {msgs?.map((msg, i) => (
                                                    <div className="swiper-slide custom-floor-wrapper li">
                                                        <div fxLayout="column" fxLayoutAlign="center center" fxFlexFill>
                                                            <span className="name" style={{ fontSize: settings?.messageMainRightFromFontSize }}>
                                                                {msg.MessageSendBy}
                                                            </span >
                                                            <span className="message">
                                                                <div dangerouslySetInnerHTML={{ __html: msg.Text }} style={{ fontSize: settings?.messageMainRightFontSize }}></div >
                                                                <div className="date" style={{ fontSize: settings?.messageMainRightDateFontSize }}> {msg.DateStart}</div >
                                                            </span >
                                                        </div >
                                                    </div >
                                                ))}
                                            </div >
                                        </div >
                                    </div >
                                </div >
                            </div >
                        </div >
                    } */}
                </div >
            </div >
        </div >


    )
}